import React, { FC } from 'react';

import { DangerouslySetInnerHtml } from 'layout';
import Image from 'common/Image';
import Button from 'components/Button';

import { useScrollTo } from 'hooks/useScrollTo';

import { CampaignBannerProps } from './models.d';

import './CampaignBanner.scss';

const CampaignBanner: FC<CampaignBannerProps> = ({
  label,
  background,
  description,
  buttons,
  productImage,
}) => {
  const [handleScrollTo] = useScrollTo();

  return (
    <div className="campaignBanner">
      <Image {...background.imageStructure} className="campaignBanner__image" />
      <div className="campaignBanner__content">
        <div className="content">
          <Image {...label.imageStructure} className="campaignBanner__label" />
          <Image {...productImage.imageStructure} className="campaignBanner__productImage" />
          <DangerouslySetInnerHtml html={description} className="campaignBanner__description" />
          <div className="campaignBanner__buttons">
            {buttons.map((button) => {
              const queryString = button.url?.includes('#') ? `#${button.url?.split('#')[1]}` : '';

              return (
                <Button
                  {...button}
                  key={button.ariaLabel}
                  className="campaignBanner__button"
                  onClick={() => handleScrollTo(queryString)}
                >
                  {button?.label}
                </Button>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignBanner;
