import React, { FC } from 'react';

import { DangerouslySetInnerHtml } from 'layout';
import Image from 'common/Image';

import { CampaignItemsProps } from './models.d';

import './CampaignItems.scss';

const CampaignItems: FC<CampaignItemsProps> = ({ title, description, items }) => (
  <div className="campaign-items">
    <div className="campaign-items__title">{title}</div>
    <DangerouslySetInnerHtml html={description} className="campaign-items__text" />

    <div className="items">
      {items.map((item) => (
        <div className="campaign-items__item">
          <div className="campaign-items__image">
            <Image {...item.icon.imageStructure} objectFit="contain" />
          </div>

          <div className="campaign-items__button">{item.button}</div>

          {item.disclaimer ? (
            <div className="campaign-items__disclaimer">{item.disclaimer}</div>
          ) : null}

          <div className="campaign-items__description">
            <DangerouslySetInnerHtml html={item.description} />
          </div>
        </div>
      ))}
    </div>
  </div>
);

export default CampaignItems;
