import React, { FC } from 'react';
import { graphql } from 'gatsby';

import { Container, Layout } from 'layout';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';
import CampaignBanner from 'components/CampaignBanner/CampaignBanner';
import CampaignContentBottom from 'components/CampaignContentBottom/CampaignContentBottom';
import CampaignContentTop from 'components/CampaignContentTop/CampaignContentTop';
import CampaignItems from 'components/CampaignItems/CampaignItems';
import CampaignVideo from 'components/CampaignVideo/CampaignVideo';

import './CampaignPage.scss';

const CampaignPage: FC<CampaignPageTypes.CampaignPageProps> = ({
  data: {
    campaignPage: {
      urls,
      seo,
      campaignBanner,
      campaignItems,
      campaignContentTop,
      campaignVideo,
      campaignContentBottom,
    },
  },
}) => {
  const { title, keywords, description } = seo;

  return (
    <Layout>
      <PageSchema
        type="WebPage"
        name={title}
        data={{
          metaTitle: title,
          metaDescription: description,
          metaKeywords: keywords,
        }}
      />
      <Seo {...{ urls, seo }} />
      <Container>
        <CampaignBanner {...campaignBanner[0]} />
        <CampaignItems {...campaignItems[0]} />
        <CampaignContentTop {...campaignContentTop[0]} />
        <CampaignVideo {...campaignVideo[0]} />
        <CampaignContentBottom {...campaignContentBottom[0]} />
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query ($lang: String, $path: [String]) {
    campaignPage(lang: { eq: $lang }, url: { in: $path }) {
      seo {
        ...SEOStructureFragment
      }
      urls {
        ...UrlsFragment
      }
      pageName
      campaignBanner {
        ...CampaignBannerFragment
      }
      campaignItems {
        ...CampaignItemsFragment
      }
      campaignContentTop {
        ...CampaignContentTopFragment
      }
      campaignVideo {
        ...CampaignVideoFragment
      }
      campaignContentBottom {
        ...CampaignContentBottomFragment
      }
    }
  }
`;

export default CampaignPage;
